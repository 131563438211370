.iframe-wrapper {
  width: calc(100vw - 94px - 25px - calc(calc(100vw - 94px) * 0.24));
  height: calc(
    calc(100vw - 94px - 25px - calc(calc(100vw - 94px) * 0.24)) * calc(9 / 16)
  );
}

@media (max-width: 1279px) {
  .iframe-wrapper {
    width: calc(100vw - 48px);
    height: calc(calc(100vw - 48px) * calc(9 / 16));
  }
}

@media (max-width: 767px) {
  .iframe-wrapper {
    width: calc(100vw - 32px);
    height: calc(calc(100vw - 32px) * calc(9 / 16));
  }
}
