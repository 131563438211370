html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

.content ul,
.content ol {
  margin-left: 36px !important;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

*:focus {
  box-shadow: none !important;
}

.shadow {
  filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.1));
}
.shadow-public-layout {
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))
    drop-shadow(0px 16px 32px rgba(0, 0, 0, 0.08));
}
.w-100 {
  width: 100%;
}
a.sidebar-link {
  padding: 0;
  margin: 0;
  margin-top: 0 !important;
}
.not-blurred {
  transform: translateZ(0);
}
.chakra-button:disabled {
  color: #828282;
  opacity: 1 !important;
}

.pagination-button {
  /* padding: 0 12px !important; */
  height: auto !important;
}

.pagination-group .chakra-button {
  padding: 0 12px;
  height: auto;
}

.committee-document-tab-list::-webkit-scrollbar {
  visibility: hidden;
  display: none;
}

.chakra-breadcrumb__list {
  display: flex;
  max-width: calc(100% - 4px);
}

.chakra-modal__content {
  margin-left: 16px;
  margin-right: 16px;
}

.chakra-modal__close-btn {
  top: 24px !important;
  right: 24px !important;
  width: fit-content !important;
  height: fit-content !important;
}
