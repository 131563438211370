@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,600;0,700;1,300;1,700&display=swap);
.article-body * {
  font-size: 16px !important;
  line-height: 22px;
  color: #202020;
}

.content table {
    width: 100% !important;
}
.iframe-wrapper {
  width: calc(100vw - 94px - 25px - calc(calc(100vw - 94px) * 0.24));
  height: calc(
    calc(100vw - 94px - 25px - calc(calc(100vw - 94px) * 0.24)) * calc(9 / 16)
  );
}

@media (max-width: 1279px) {
  .iframe-wrapper {
    width: calc(100vw - 48px);
    height: calc(calc(100vw - 48px) * calc(9 / 16));
  }
}

@media (max-width: 767px) {
  .iframe-wrapper {
    width: calc(100vw - 32px);
    height: calc(calc(100vw - 32px) * calc(9 / 16));
  }
}

.user-activity-section-heading > p {
  min-width: auto;
}

.message-body * {
  font-size: 16px !important;
  line-height: 22px;
}

.message-body body,
.message-body div {
	font-family: Verdana, Arial, sans-serif;
	font-size: 10px;
	color: #000;
	background-color: inherit !important;
}

.message-body div div {
	background-color: transparent
}

.message-body [data-layout=true] {
	margin: 0 auto;
	max-width: 600px
}

.message-body p {
	margin: 0;
	padding: 0;
	line-height: 125%;
	line-height: 1.25;
	mso-line-height-rule: at-least
}

.message-body [dir=rtl],
.message-body [dir=rtl] * {
	text-align: right
}

.message-body s span {
	text-decoration: line-through
}

.message-body u span {
	text-decoration: underline
}

.message-body .dividerWrapper p span {
	line-height: 0
}

.message-body ol,
.message-body ul {
	margin-top: 15px;
	margin-bottom: 15px;
	line-height: 125%;
	line-height: 1.25;
	mso-line-height-rule: at-least;
	padding-inline-end: 40px !important
}

/* .message-body li span {
	display: block
} */

.message-body p img {
	max-width: 100%
}

.message-body a {
	text-decoration: none
}

.message-body u a {
	text-decoration: underline
}

.message-body h1 {
	font-size: 28px
}

.message-body h2 {
	font-size: 22px
}

.message-body h3 {
	font-size: 18px
}

.message-body h1,
.message-body h2,
.message-body h3,
.message-body h4 {
	margin: 0
}

.message-body .imageWrapper a img {
	text-decoration: none;
	border: 0
}

.message-body tbody tr td {
	min-width: 100px;
}

.message-body th.columnContainer,
.message-body th.inner {
	font-weight: 400;
	text-align: left
}

.message-body [dir=rtl] th.columnContainer,
.message-body [dir=rtl] th.inner {
	font-weight: 400;
	text-align: right
}

@media only screen and (max-width:768px) {
	.message-body .tbContainer {
		display: block !important
	}

	.message-body a span {
		line-height: inherit !important
	}

	.message-body .multi table {
		table-layout: fixed;
		height: auto !important
	}

	.message-body .multi td {
		width: auto !important;
		min-height: auto !important
	}

	.message-body .multi tbody,
	.message-body .multi tbody tr,
	.message-body .multi tbody tr td,
	.message-body .multi tbody tr th,
	.message-body .outer tbody,
	.message-body .outer tbody tr,
	.message-body .outer tbody tr td,
	.message-body .outer tbody tr th {
		display: block;
		width: 100% !important;
		box-sizing: border-box;
		height: auto !important;
		min-height: auto !important
	}

	.message-body .multi .inner {
		height: auto !important;
		min-height: auto !important
	}

	.message-body .tbContainer .inner {
		box-sizing: border-box
	}

	.message-body .outer {
		width: 100% !important
	}

	.message-body .tbContainer .columnContainer table {
		table-layout: fixed !important
	}

	.message-body .innerTable {
		min-height: auto !important
	}

	.message-body .no-wrap-section .outer .multi tbody tr td,
	.message-body .no-wrap-section .outer .multi tbody tr th {
		display: table-cell !important
	}

	.message-body .no-wrap-section .outer .multi tbody tr td.inner,
	.message-body .no-wrap-section .outer .multi tbody tr th.inner {
		display: block !important
	}

	.message-body .no-wrap-section .outer .multi .dividerWrapper tbody tr td,
	.message-body .no-wrap-section .outer .multi .dividerWrapper tbody tr th {
		display: block !important
	}

	.message-body .no-wrap-section .containerWrapper tr {
		display: flex !important
	}

	.message-body .no-wrap-section .containerWrapper .innerTable tr {
		display: block !important
	}

	.message-body a[x-apple-data-detectors] {
		color: inherit !important;
		text-decoration: none !important;
		font-size: inherit !important;
		font-family: inherit !important;
		font-weight: inherit !important;
		line-height: inherit !important
	}
}
.delete-button {
  margin-top: 29px !important;
  display: flex;
}

.delete-button > button {
  min-width: 22px !important;
}



html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

.content ul,
.content ol {
  margin-left: 36px !important;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

*:focus {
  box-shadow: none !important;
}

.shadow {
  filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.1));
}
.shadow-public-layout {
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))
    drop-shadow(0px 16px 32px rgba(0, 0, 0, 0.08));
}
.w-100 {
  width: 100%;
}
a.sidebar-link {
  padding: 0;
  margin: 0;
  margin-top: 0 !important;
}
.not-blurred {
  transform: translateZ(0);
}
.chakra-button:disabled {
  color: #828282;
  opacity: 1 !important;
}

.pagination-button {
  /* padding: 0 12px !important; */
  height: auto !important;
}

.pagination-group .chakra-button {
  padding: 0 12px;
  height: auto;
}

.committee-document-tab-list::-webkit-scrollbar {
  visibility: hidden;
  display: none;
}

.chakra-breadcrumb__list {
  display: flex;
  max-width: calc(100% - 4px);
}

.chakra-modal__content {
  margin-left: 16px;
  margin-right: 16px;
}

.chakra-modal__close-btn {
  top: 24px !important;
  right: 24px !important;
  width: -webkit-fit-content !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
  height: -webkit-fit-content !important;
  height: -moz-fit-content !important;
  height: fit-content !important;
}

